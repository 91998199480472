.stu-exxam-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease;
    height: 33vh;
    width: 50%;
    margin-bottom: 100px;
}

.stu-exam-card:hover {
    transform: scale(1.03);
}

.students {
    border: 2px solid rgba(0, 0, 0, 0.2);
    padding: 20px 0 0 0;
    border-radius: 10px;
    box-shadow: 8px 8px 16px #C8CBD0, -8px -8px 16px #fff;
}

.stu-inner {
    width: 220px;
    position: absolute;
    top: 400px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 15vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-shadow: inset 4px 4px 8px rgba(200, 203, 208, 0.25), inset -4px -4px 8px rgba(255, 255, 255, 0.7);
}

.stu-inner h5 {
    width: 150px;
    height: 6vh;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
    box-shadow: 4px 4px 8px #C8CBD0, -4px -4px 8px #ffffff;
}
.stu-stu-inner {
    width: 220px;
    position: absolute;
    top: 400px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 15vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-shadow: inset 4px 4px 8px rgba(200, 203, 208, 0.25), inset -4px -4px 8px rgba(255, 255, 255, 0.7);
}

.stu-stu-inner h5 {
    width: 150px;
    height: 6vh;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
    box-shadow: 4px 4px 8px #C8CBD0, -4px -4px 8px #ffffff;
}
.search-box {
    width: 20%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    outline: none;
    position: relative;
    right: 0;
    left: 78%;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.table th,
.table td {
    padding: 12px 15px;
}

.table th {
    font-weight: bold;
}

.table thead tr {
    border-bottom: 2px solid #ddd;
    color: #1A3874;
}

.table tbody tr td:nth-of-type(1) {
    border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.table tbody tr:hover {
    box-shadow: 10px 10px 20px rgba(164, 177, 196, 0.3);
    border-radius: 20px;
}

.table tbody tr td:nth-of-type(1):hover {
    box-shadow: none;
}

.search {
    width: 100%;
    padding: 10px;
    margin: 10px 0 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.stu-inner h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .stu-exxam-card {
        width: 60%;
        height: 30vh;
        margin-bottom: 80px;
    }

    .students {
        padding: 15px 0 0 0;
    }

    .stu-inner {
        width: 200px;
        height: 12vh;
        top: 350px;
        left: 20px;
    }

    .stu-inner h5 {
        width: 130px;
        height: 5vh;
        font-size: 16px;
    }

    .search-box {
        width: 25%;
        left: 73%;
        padding: 8px;
    }
    .table-block{
        overflow-x: scroll;
    }
    .table {
        font-size: 14px;
    }

    .table th,
    .table td {
        padding: 10px 12px;
    }

    .search {
        padding: 8px;
    }

    .stu-inner h2 {
        font-size: 20px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .stu-exxam-card {
        width: 100%; /* Full width for single-column layout */
        height: 35vh;
        margin-bottom: 60px;
    }

    .students {
        padding: 10px 0 0 0;
    }

    .stu-inner {
        width: 180px;
        height: 10vh;
        top: 340px;
        left: 20px;
    }

    .stu-inner h5 {
        width: 120px;
        height: 4vh;
        font-size: 14px;
    }
    .stu-stu-inner {
        width: 180px;
        height: 10vh;
        top: 386px;
        left: 20px;
    }

    .stu-stu-inner h5 {
        width: 120px;
        height: 4vh;
        font-size: 14px;
    }
    .search-box {
        width: 24%;
        padding: 6px;
        margin-bottom: 15px;
    }
    .table-block{
        overflow-x: scroll;
    }
    .table {
        font-size: 13px;
    }

    .table th,
    .table td {
        padding: 8px 10px;
    }

    .table tbody tr:hover {
        box-shadow: 5px 5px 10px rgba(164, 177, 196, 0.3); /* Reduced shadow */
    }

    .search {
        padding: 6px;
        margin: 8px 0 15px;
    }

    .stu-inner h2 {
        font-size: 18px;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .stu-exxam-card {
        width: 100%;
        height: 30vh; /* Taller to fit content */
        margin-bottom: 40px;
    }

    .students {
        padding: 8px 0 0 0;
        border-width: 1px;
    }

    .stu-inner {
        width: 150px;
        height: 8vh;
        top: 350px;
        left: 15px;
        display: none;
    }

    .stu-inner h5 {
        width: 100px;
        height: 3.5vh;
        font-size: 12px;
    }
    .stu-stu-inner {
        width: 150px;
        height: 8vh;
        top: 330px;
        left: 20px;
        display: none;
    }

    .stu-stu-inner h5 {
        width: 100px;
        height: 3.5vh;
        font-size: 12px;
    }
    .search-box {
        width: 23%;
        padding: 5px;
        margin-bottom: 10px;
    }
    .table-block{
        overflow-x: scroll;
    }
    .table {
        font-size: 12px;
        overflow-x: scroll;
    }

    .table th,
    .table td {
        padding: 6px 8px;
    }

    .table thead tr {
        border-bottom: 1px solid #ddd;
    }

    .table tbody tr td:nth-of-type(1) {
        border-right: none; /* Remove border for small screens */
    }

    .table tbody tr:hover {
        box-shadow: none; /* Remove shadow on hover for small screens */
    }

    .search {
        padding: 5px;
        margin: 5px 0 10px;
        border: solid;
    }

    .stu-inner h2 {
        font-size: 16px;
        margin-bottom: 8px;
    }
}