.create-exam-ai {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    height: 80vh;
    width: 100%;
    box-sizing: border-box;
}

.create-exam-ai-left {
    width: 40%;
    display: flex;
    height: 80vh;
    justify-content: space-between;
}

.gea-exam-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
}

.gea-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.gea-form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
}

.gea-label {
    font-size: 14px;
    font-weight: bold;
}

.gea-input,
.gea-select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.gea-sub {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gea-vertical-line {
    position: absolute;
    top: 11%;
    left: 45%;
    transform: translateX(-50%);
    height: 100vh;
    width: 1px;
    background-color: #000;
    opacity: 0.5;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.create-exam-ai-right {
    width: 50%;
    padding: 20px;
    overflow-y: auto;
}

/* .create-exam-ai-right::-webkit-scrollbar {
    display: none;
} */

.gea-questions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gea-questions-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.gea-questions-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.gea-question-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.gea-question-text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.gea-options-list {
    list-style: none;
    padding-left: 20px;
}

.gea-option-item {
    font-size: 13px;
    color: #555;
}

.gea-no-questions {
    font-size: 14px;
    color: #999;
    text-align: center;
}

.gea-ques-sub {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.gea-sub-btn {
    margin-top: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    gap: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
}

.gea-questions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.gea-edit-button {
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 16px #fff, -8px -8px 16px #fff;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .create-exam-ai {
        height: 70vh;
        margin-top: 20px;
    }

    .create-exam-ai-left {
        width: 45%;
        height: 70vh;
    }

    .create-exam-ai-right {
        width: 50%;
        padding: 15px;
    }

    .gea-exam-form {
        gap: 20px;
    }

    .gea-row {
        gap: 15px;
    }

    .gea-form-group {
        width: 48%;
    }

    .gea-label {
        font-size: 13px;
    }

    .gea-input,
    .gea-select {
        padding: 8px;
        font-size: 13px;
    }

    .gea-vertical-line {
        left: 47%;
    }

    .gea-questions-title {
        font-size: 16px;
    }

    .gea-question-item {
        padding: 8px;
    }

    .gea-question-text {
        font-size: 13px;
    }

    .gea-option-item {
        font-size: 12px;
    }

    .gea-sub-btn {
        padding: 8px 15px;
        font-size: 14px;
    }

    .gea-edit-button {
        padding: 4px 8px;
        font-size: 13px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .create-exam-ai {
        flex-direction: column; /* Stack left and right sections */
        height: auto;
        margin-top: 15px;
    }

    .create-exam-ai-left {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .create-exam-ai-right {
        width: 100%;
        padding: 10px;
        max-height: 50vh; /* Limit height for scrolling */
    }

    .gea-exam-form {
        gap: 15px;
    }

    .gea-row {
        flex-direction: column; /* Stack form groups vertically */
        gap: 10px;
    }

    .gea-form-group {
        width: 100%; /* Full width for form groups */
    }

    .gea-label {
        font-size: 12px;
    }

    .gea-input,
    .gea-select {
        padding: 6px;
        font-size: 12px;
    }

    .gea-vertical-line {
        display: none; /* Hide vertical line on mobile */
    }

    .gea-questions-title {
        font-size: 15px;
    }

    .gea-question-item {
        padding: 6px;
        margin-bottom: 8px;
    }

    .gea-question-text {
        font-size: 12px;
    }

    .gea-options-list {
        padding-left: 10px;
    }

    .gea-option-item {
        font-size: 11px;
    }

    .gea-no-questions {
        font-size: 13px;
    }

    .gea-sub-btn {
        padding: 6px 12px;
        font-size: 13px;
        width: 100%; /* Full width button */
        max-width: 200px;
    }

    .gea-edit-button {
        padding: 3px 6px;
        font-size: 12px;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .create-exam-ai {
        margin-top: 10px;
    }

    .create-exam-ai-left {
        padding: 5px;
    }

    .create-exam-ai-right {
        padding: 5px;
        max-height: 40vh;
    }

    .gea-exam-form {
        gap: 10px;
    }

    .gea-row {
        gap: 8px;
    }

    .gea-label {
        font-size: 11px;
    }

    .gea-input,
    .gea-select {
        padding: 5px;
        font-size: 11px;
    }

    .error-message {
        font-size: 10px;
    }

    .gea-questions-title {
        font-size: 14px;
    }

    .gea-question-item {
        padding: 5px;
        margin-bottom: 6px;
    }

    .gea-question-text {
        font-size: 11px;
    }

    .gea-option-item {
        font-size: 10px;
    }

    .gea-no-questions {
        font-size: 12px;
    }

    .gea-sub-btn {
        padding: 5px 10px;
        font-size: 12px;
        max-width: 150px;
        height: 4vh;
        margin-bottom: 10px;
        margin-right: 30%;
    }

    .gea-edit-button {
        padding: 2px 5px;
        font-size: 11px;
    }
}