.student-exam-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.student-exam-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease;
    height: 35vh;
    width: 100%; /* Changed from fixed width to responsive */
}

.student-exam-card:hover {
    transform: scale(1.03);
}

.student-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 15px;
    background-color: #E7F0FC;
    border-radius: 10px;
    font-weight: 500;
    height: 8vh;
}

.student-card-header p {
    font-size: 0.9em;
}

.student-status {
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 15px;
    text-transform: capitalize;
}

.student-status.running {
    background: #FFDDDD;
}

.student-status.completed {
    background: #DDFFDF;
}

.student-card-body {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 0 15px;
}

.student-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.student-subject-info {
    display: flex;
    align-items: center;
}

.student-subject-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.student-subject-info h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
}

.student-subject-info p {
    margin: 0;
    font-size: 0.9em;
    color: #888;
}

.student-view-more-btn {
    display: inline-block;
    font-size: 12px;
    padding: 10px;
    font-family: Arial, sans-serif;
    color: #000;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    width: 79px;
}

.student-view-more-btn:hover {
    background-color: #f7f7f7;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.student-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.student-stat-item {
    text-align: left;
    position: relative;
}

.student-stats p {
    margin: 0;
    font-size: 1.5em;
    color: #333;
    font-weight: 100;
}

.student-stats span {
    display: block;
    font-size: 0.8em;
    color: #637db2;
    font-weight: 200;
}

/* Vertical Line */
.student-vertical-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30%;
    transform: translateX(-50%);
    height: 40px;
    width: 1px;
    background-color: #000;
    opacity: 0.5;
}

/* Horizontal Line */
hr {
    border: 1px solid #000;
    opacity: 0.5;
}

.student-start-btn {
    border: solid;
    background-color: #3372ED;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left: 90%;
    width: 100%;
}

.student-start-item {
    border: solid;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .student-exam-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        padding: 15px;
    }

    .student-exam-card {
        height: 32vh;
    }

    .student-card-header {
        padding: 0 10px;
        height: 7vh;
    }

    .student-card-header p {
        font-size: 0.85em;
    }

    .student-status {
        font-size: 0.75em;
        padding: 4px 8px;
    }

    .student-card-body {
        padding: 8px 10px 0 10px;
    }

    .student-subject-icon img {
        width: 35px;
        height: 35px;
    }

    .student-subject-info h3 {
        font-size: 1.1em;
    }

    .student-subject-info p {
        font-size: 0.85em;
    }

    .student-view-more-btn {
        padding: 8px;
        font-size: 11px;
    }

    .student-stats {
        gap: 8px;
    }

    .student-stats p {
        font-size: 1.3em;
    }

    .student-stats span {
        font-size: 0.75em;
    }

    .student-start-btn {
        height: 35px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .student-exam-list {
        grid-template-columns: 1fr; /* Single column */
        gap: 20px;
        padding: 10px;
    }

    .student-exam-card {
        height: 30vh; /* Slightly taller for single-column layout */
    }

    .student-card-header {
        padding: 0 8px;
        height: 6vh;
    }

    .student-card-header p {
        font-size: 0.8em;
    }

    .student-status {
        font-size: 0.7em;
        padding: 3px 6px;
    }

    .student-card-body {
        padding: 6px 8px 0 8px;
    }

    .student-subject-icon img {
        width: 30px;
        height: 30px;
    }

    .student-subject-info h3 {
        font-size: 1em;
    }

    .student-subject-info p {
        font-size: 0.8em;
    }

    .student-view-more-btn {
        padding: 6px;
        font-size: 10px;
        width: 70px;
    }

    .student-stats {
        grid-template-columns: repeat(4, 1fr); /* 2 columns instead of 4 */
        gap: 6px;
    }

    .student-stats p {
        font-size: 1.2em;
    }

    .student-stats span {
        font-size: 0.7em;
    }

    .student-vertical-line {
        left: -15%; /* Adjusted for narrower layout */
        height: 30px;
    }

    .student-start-btn {
        margin-left: 0; /* Center it */
        width: 50%;
        max-width: 150px;
        height: 30px;
        margin: 0 auto; /* Centered */
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .student-exam-list {
        gap: 15px;
        padding: 8px;
    }

    .student-exam-card {
        height: 27vh; /* Taller for better content fit */
    }

    .student-card-header {
        padding: 0 6px;
        height: 5vh;
    }

    .student-card-header p {
        font-size: 0.75em;
    }

    .student-status {
        font-size: 0.65em;
        padding: 2px 5px;
    }

    .student-card-body {
        padding: 5px 6px 0 6px;
    }

    .student-subject-icon img {
        width: 25px;
        height: 25px;
    }

    .student-subject-info h3 {
        font-size: 0.9em;
    }

    .student-subject-info p {
        font-size: 0.75em;
    }

    .student-view-more-btn {
        padding: 5px;
        font-size: 9px;
        width: 60px;
    }

    .student-stats {
        gap: 5px;
    }

    .student-stats p {
        font-size: 1em;
        font-weight: 500;
    }

    .student-stats span {
        font-size: 0.65em;
        font-weight: 400;
    }

    .student-vertical-line {
        height: 25px;
    }

    .student-start-btn {
        width: 60%;
        max-width: 120px;
        height: 25px;
        font-size: 0.9em;
    }
}