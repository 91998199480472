@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

.teacher-home {
    font-family: 'Arial', sans-serif;
    width: 90%;
    position: relative;
    top: 17vh;
    left: 5%;
    padding: 10px;
    height: auto; /* Changed from fixed height to auto for flexibility */
    z-index: 2;
}

.welcome-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
}

.welcome-name {
    font-size: 2.2rem;
    font-weight: 100;
}

.text {
    font-size: 1.3em;
    opacity: 0.5;
    margin-top: 8px;
}

/* Search Section */
.search-section {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
}

.search-bar {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    height: 5vh;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #F6F8FA;
    outline: none;
}

.search-results-dropdown {
    position: absolute;
    top: 110%;
    left: 15%;
    width: 84%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-result-item {
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item:hover {
    background: #f8f8f8;
}

.classes-section {
    margin-bottom: 20px;
    position: relative;
    top: -20px;
}

.class-buttons button {
    margin-right: 20px;
    padding: 10px 40px;
    border: 1px solid #bbbbbb;
    border-radius: 20px;
    cursor: pointer;
    background-color: #F2F8FF;
    outline: none;
    font-weight: 500;
    transition: all 0.4s ease;
    width: fit-content;
    height: 50px;
    font-size: 21px;
    margin-bottom: 10px;
}

.cls-btn:active,
.cls-btn.active {
    background-color: #C2D9F6;
    color: rgb(0, 0, 0);
    border: none;
    outline: none;
}

.overview-sction {
    margin: 30px 0;
}

.over {
    font-weight: normal;
    font-size: 1.3em;
    opacity: 0.8;
    margin-bottom: 20px;
}

.start-exam-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 70px;
}

.exam-option {
    flex: 1;
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: transparent;
    height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.exam-option h3 {
    color: #032872;
}

.generate-button {
    margin-top: 10px;
    background-color: #fff;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    gap: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    width: 100%; /* Changed to 100% for responsiveness */
    max-width: 500px; /* Maintain max width */
}

.generate-button:hover {
    transform: translateY(-5px);
}

.ai-img-block {
    width: 95%;
    height: 60%;
}

.ai-img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure images scale properly */
}

.nonai-img-block {
    height: 60%;
    width: 95%;
}

.nonai-img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure images scale properly */
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .teacher-home {
        width: 92%;
        left: 4%;
        padding: 8px;
    }

    .welcome-name {
        font-size: 1.8rem;
        font-weight: bold;
    }

    .text {
        font-size: 1.1em;
    }

    .search-section {
        max-width: 350px;
    }

    .class-buttons button {
        padding: 8px 30px;
        font-size: 18px;
        height: 45px;
    }

    .start-exam-section {
        gap: 15px;
        margin-top: 50px;
    }

    .exam-option {
        height: 30vh;
        padding: 15px;
    }

    .generate-button {
        padding: 8px 15px;
        font-size: 14px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .teacher-home {
        width: 94%;
        left: 3%;
        top: 12vh; /* Reduced to fit smaller screens */
        padding: 5px;
    }

    .welcome-section {
        height: auto;
        padding: 10px 0;
    }

    .welcome-name {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .text {
        font-size: 1em;
        margin-top: 5px;
    }

    .search-section {
        max-width: 50%;
        justify-content: flex-start;
    }

    .search-bar {
        font-size: 14px;
        height: 4vh;
    }

    .search-results-dropdown {
        left: 0;
        width: 100%;
    }

    .class-buttons {
        display: flex;
        flex-wrap: wrap; /* Allow buttons to wrap */
        gap: 10px;
    }

    .class-buttons button {
        margin-right: 10px;
        padding: 8px 20px;
        font-size: 16px;
        height: 40px;
    }

    .start-exam-section {
        flex-direction: column; /* Stack exam options vertically */
        gap: 20px;
        margin-top: 40px;
    }

    .exam-option {
        height: 25vh;
        padding: 10px;
    }

    .generate-button {
        width: 100%;
        max-width: none; /* Remove max-width restriction */
        padding: 8px 10px;
        font-size: 14px;
    }

    .ai-img-block,
    .nonai-img-block {
        height: 50%;
        width: 100%;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .teacher-home {
        width: 96%;
        left: 2%;
        top: 10vh;
    }

    .welcome-name {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .text {
        font-size: 0.9em;
    }

    .search-bar {
        font-size: 12px;
        height: 3.5vh;
    }

    .search-result-item {
        font-size: 14px;
        padding: 8px;
    }

    .class-buttons button {
        padding: 6px 15px;
        font-size: 14px;
        height: 35px;
        margin-right: 5px;
    }

    .over {
        font-size: 1.1em;
    }

    .exam-option {
        height: 20vh;
    }

    .exam-option h3 {
        font-size: 1.1rem;
    }

    .generate-button {
        padding: 6px 8px;
        font-size: 12px;
    }

    .ai-img-block,
    .nonai-img-block {
        height: 40%;
    }
}