.stu-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    padding: 20px 0;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
}

.stu-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 317px;
    height: 140px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1), -5px -5px 15px rgba(255, 255, 255, 0.7);
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.stu-card:hover {
    transform: translateY(-5px);
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2), -8px -8px 20px rgba(255, 255, 255, 0.8);
}

.stu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: inset 8px 8px 16px rgba(200, 203, 208, 0.6), inset -8px -8px 16px rgba(255, 255, 255, 0.8);
}

.stu-circle-ico {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.stu-space {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    width: 50%;
}

.stu-value {
    font-size: 40px;
    font-weight: bold;
    color: #000;
}

.stu-label {
    font-size: 14px;
    margin-top: 5px;
    opacity: 0.5;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .stu-container {
        flex-wrap: wrap; /* Allow cards to wrap */
        gap: 20px;
        padding: 15px 0;
    }

    .stu-card {
        width: 280px;
        height: 120px;
    }

    .stu-icon {
        width: 60px;
        height: 60px;
    }

    .stu-circle-ico {
        width: 40px;
        height: 40px;
    }

    .stu-value {
        font-size: 35px;
    }

    .stu-label {
        font-size: 13px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .stu-container {
        
        gap: 15px;
        padding: 10px 0;
    }

    .stu-card {
        width: 100%; /* Full width */
        max-width: 300px;
        height: 110px;
    }

    .stu-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
    }

    .stu-circle-ico {
        width: 35px;
        height: 35px;
    }

    .stu-space {
        width: 60%;
    }

    .stu-value {
        font-size: 30px;
    }

    .stu-label {
        font-size: 12px;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .stu-container {
        gap: 10px;
        padding: 8px 0;
    }

    .stu-card {
        width: 100%;
        max-width: 250px;
        height: 100px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), -3px -3px 10px rgba(255, 255, 255, 0.7);
    }

    .stu-card:hover {
        transform: translateY(-3px);
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.8);
    }

    .stu-icon {
        width: 40px;
        height: 40px;
    }

    .stu-circle-ico {
        width: 30px;
        height: 30px;
    }

    .stu-space {
        width: 65%;
    }

    .stu-value {
        font-size: 25px;
    }

    .stu-label {
        font-size: 11px;
        margin-top: 3px;
    }
}