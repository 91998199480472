.exam-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.exxam-card,.studen-exxam-card {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease;
    height: 33vh;
    width: 100%; /* Changed from fixed width to responsive */
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.exxam-card:hover,.studen-exxam-card:hover {
    transform: scale(1.03);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 15px;
    background-color: #E7F0FC;
    border-radius: 20px;
    font-weight: 500;
    height: 10vh;
    margin-top: 0;
    box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.1), -5px -5px 9px rgba(255, 255, 255, 0.1);
}

.card-header p {
    font-size: 0.9em;
}

.stattus {
    font-size: 0.9em;
    padding: 8px 15px;
    border-radius: 30px;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #000;
    width: fit-content;
}

.stattus.running {
    background: #f13f3f;
}

.stattus.pending {
    background: #fbf4a7f4;
}

.stattus.completed {
    background: #76df869d;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 0 15px;
}

.sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subject-info {
    display: flex;
    align-items: center;
}

.subject-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.subject-info h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
}

.subject-info p {
    margin: 0;
    font-size: 0.9em;
    color: #888;
}

.view-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 12px;
    padding: 10px;
    font-family: Arial, sans-serif;
    color: #000;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-decoration: none;
    width: fit-content;
}

.view-more-btn:hover {
    background-color: #f7f7f7;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.stats, .e-stats, .stu-e-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.stat-item {
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.stats p, .e-stats p, .stu-e-stats p {
    margin: 0;
    font-size: 1.7em;
    color: #000;
    font-weight: 100;
}

.stats span, .e-stats span, .stu-e-stats span {
    display: block;
    font-size: 0.8em;
    color: #003498;
    font-weight: 400;
}

/* Vertical Line */
.vertical-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30%;
    transform: translateX(-50%);
    height: 40px;
    width: 1px;
    background-color: #000;
    opacity: 0.5;
}

/* Horizontal Line */
hr {
    border: 1px solid #000;
    opacity: 0.5;
}

.start-btn {
    border: solid;
    background-color: #3372ED;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left: 90%;
    width: 100%;
}

.start-item {
    border: solid;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .exam-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        padding: 15px;
    }

    .exxam-card, .studen-exxam-card {
        height: 30vh;
    }

    .card-header {
        padding: 0 10px;
        height: 8vh;
    }

    .card-header p {
        font-size: 0.8em;
    }

    .stattus {
        font-size: 0.8em;
        padding: 6px 12px;
    }

    .card-body {
        padding: 8px 10px 0 10px;
    }

    .subject-icon img {
        width: 35px;
        height: 35px;
    }

    .subject-info h3 {
        font-size: 1.1em;
    }

    .subject-info p {
        font-size: 0.8em;
    }

    .view-more-btn {
        padding: 8px;
        font-size: 11px;
    }

    .stats {
        gap: 8px;
    }

    .stats p {
        font-size: 1.5em;
    }

    .stats span {
        font-size: 0.7em;
    }

    .start-btn {
        height: 35px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .exam-list {
        grid-template-columns: 1fr; /* Single column */
        gap: 20px;
        padding: 10px;
        
    }

    .exxam-card,.studen-exxam-card {
        height: 35vh; /* Slightly taller for single-column layout */
    }

    .card-header {
        padding: 0 8px;
        height: 7vh;
    }

    .card-header p {
        font-size: 0.75em;
    }
    .stattus {
        font-size: 0.75em;
        padding: 5px 10px;
    }

    .card-body {
        padding: 6px 8px 0 8px;
        height: 25vh;
    }

    .subject-icon img {
        width: 30px;
        height: 30px;
    }

    .subject-info h3 {
        font-size: 1em;
    }

    .subject-info p {
        font-size: 0.75em;
    }

    .view-more-btn {
        padding: 6px;
        font-size: 10px;
    }

    .stats {
        grid-template-columns: repeat(2, 1fr); /* 2 columns instead of 4 */
        gap: 6px;
    }

    .stats p {
        font-size: 1.3em;
    }

    .stats span {
        font-size: 0.65em;
    }

    .vertical-line {
        left: -15%; /* Adjusted for narrower layout */
        height: 30px;
    }

    .start-btn {
        margin-left: 0; /* Center it */
        width: auto;
        max-width: 150px;
        height: 35px;
        margin: 0 auto; /* Centered */
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .exam-list {
        gap: 15px;
        padding: 8px;
    }

    .exxam-card {
        height: 30vh; /* Taller for better content fit */
    }
    .studen-exxam-card{
        height: 25vh;
    }
    .card-header {
        padding: 0 6px;
        height: 6vh;
    }

    .card-header p {
        font-size: 0.7em;
    }

    .stattus {
        font-size: 0.7em;
        padding: 4px 8px;
    }

    .card-body {
        padding: 5px 6px 0 6px;
    }

    .subject-icon img {
        width: 25px;
        height: 25px;
    }

    .subject-info h3 {
        font-size: 0.9em;
    }

    .subject-info p {
        font-size: 0.7em;
    }

    .view-more-btn {
        padding: 5px;
        font-size: 9px;
    }

    .stats, .e-stats {
        gap: 5px;
    }
    .stu-e-stats{
        /* border: solid; */
    }
    .stats p, .e-stats p, .stu-e-stats p {
        font-size: 1.2em;
    }

    .stats span, .e-stats span, .stu-e-stats span {
        font-size: 0.6em;
    }

    .vertical-line {
        height: 25px;
    }

    .start-btn {
        width: 100%;
        height: 35px;
        font-size: 0.9em;
        text-align: center;
        margin-left: 100%;
        /* background-color: transparent; */
    }

}