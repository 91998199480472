.exam-stats-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
}

.exam-stats-container h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 20px;
}

.subjects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
    gap: 20px;
}

.subject-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
}

.subject-card:hover {
    transform: scale(1.05);
}

.subject-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.circle-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #C2D9F6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
}

.subject-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
}

.subject-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.subject-name {
    font-size: 1em;
    color: #333;
}

.subject-count {
    font-size: 1.5em;
    color: #007bff;
}

.subject-card.active {
    background-color: #C2D9F6;
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .exam-stats-container {
        padding: 15px;
    }

    .exam-stats-container h3 {
        font-size: 1.3em;
        margin-bottom: 15px;
    }

    .subjects-grid {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 15px;
    }

    .subject-card {
        padding: 15px;
    }

    .circle-icon,
    .subject-image {
        width: 50px;
        height: 50px;
    }

    .circle-icon {
        font-size: 1.3em;
    }

    .subject-name {
        font-size: 0.95em;
    }

    .subject-count {
        font-size: 1.3em;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .exam-stats-container {
        padding: 10px;
    }

    .exam-stats-container h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    .subjects-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
    }

    .subject-card {
        padding: 10px;
        flex-direction: column; /* Stack icon and details vertically */
        justify-content: center;
    }

    .subject-icon {
        margin-bottom: 5px;
    }

    .circle-icon,
    .subject-image {
        width: 45px;
        height: 45px;
    }

    .circle-icon {
        font-size: 1.2em;
    }

    .subject-details {
        gap: 5px;
    }

    .subject-name {
        font-size: 0.9em;
    }

    .subject-count {
        font-size: 1.2em;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .exam-stats-container {
        padding: 8px;
    }

    .exam-stats-container h3 {
        font-size: 1.1em;
        margin-bottom: 8px;
    }

    .subjects-grid {
        grid-template-columns: 1fr; /* Single column */
        gap: 8px;
    }

    .subject-card {
        padding: 8px;
        margin-bottom: 8px;
    }

    .circle-icon,
    .subject-image {
        width: 40px;
        height: 40px;
    }

    .circle-icon {
        font-size: 1.1em;
    }

    .subject-name {
        font-size: 0.85em;
    }

    .subject-count {
        font-size: 1.1em;
    }
}