.stu-details-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.stu-left-details,
.stu-right-details {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
}

.stu-section-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.4rem;
  color: #1976d2;
  font-weight: 600;
}

.info-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.stu-pd span {
  color: #666;
  font-size: 0.9rem;
}

.stu-pd h5 {
  font-size: 1.1rem;
  margin-top: 0.25rem;
}

.stu-exam-card {
  background: #eaeaeb;
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  transition: transform 0.2s;
  width: 90%;
}

.stu-exam-card:hover {
  transform: translateY(-2px);
}

.status {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 10px;
  height: 3vh;
}

.status.passed {
  background: #e8f5e9;
  color: #2e7d32;
}

.status.failed {
  background: #ffebee;
  color: #c62828;
}

.stu-overview-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.stu-overview-card {
  text-align: center;
  background: #f5f5f5;
  padding: 10px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.stu-overview-card.passed {
  background: #e8f5e9;
  color: #2e7d32;
}

.stu-overview-card.failed {
  background: #ffebee;
  color: #c62828;
}

.stu-overview-card.average-grade {
  background: #e3f2fd;
}

.stu-exam-history {
  margin-top: 20px;
}

.stu-exam-scroll {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.stu-exam-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ttt {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.exam-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
  .stu-details-block {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
  }

  .stu-left-details,
  .stu-right-details {
      padding: 1.5rem;
  }

  .stu-section-header {
      font-size: 1.3rem;
      gap: 0.5rem;
  }

  .info-row {
      gap: 1rem;
  }

  .stu-pd span {
      font-size: 0.85rem;
  }

  .stu-pd h5 {
      font-size: 1rem;
  }

  .stu-exam-card {
      padding: 1.2rem;
      width: 100%; /* Slightly wider */
  }

  .status {
      padding: 0.2rem 0.6rem;
      height: 2.5vh;
  }

  .stu-overview-cards {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
      gap: 1rem;
  }

  .stu-overview-card {
      padding: 8px 0;
  }

  .stu-exam-scroll {
      grid-template-columns: 1fr; /* Single column */
  }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
  .stu-details-block {
      grid-template-columns: 1fr; /* Single column */
      gap: 1rem;
  }

  .stu-left-details,
  .stu-right-details {
      padding: 1rem;
  }

  .stu-section-header {
      font-size: 1.2rem;
      gap: 0.4rem;
  }

  .info-row {
      grid-template-columns: 1fr; /* Single column */
      gap: 0.75rem;
  }

  .stu-pd span {
      font-size: 0.8rem;
  }

  .stu-pd h5 {
      font-size: 0.95rem;
  }

  .stu-exam-card {
      padding: 1rem;
      width: 100%;
  }

  .status {
      padding: 0.15rem 0.5rem;
      height: 2vh;
      font-size: 0.9rem;
  }

  .stu-overview-cards {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;
  }

  .stu-overview-card {
      padding: 6px 0;
      font-size: 0.9rem;
  }

  .stu-exam-history {
      margin-top: 15px;
  }

  .stu-exam-scroll {
      grid-template-columns: 1fr;
  }

  .stu-exam-details {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
  }

  .exam-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
  }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
  .hero{
    flex-direction: column;
    align-items: flex-start;
  }
  .vertical{
    display: none;
  }
  .stu-details-block {
      gap: 0.75rem;
  }

  .stu-left-details,
  .stu-right-details {
      padding: 0.75rem;
  }

  .stu-section-header {
      font-size: 1.1rem;
      gap: 0.3rem;
  }

  .info-row {
      gap: 0.5rem;
  }

  .stu-pd span {
      font-size: 0.75rem;
  }

  .stu-pd h5 {
      font-size: 0.9rem;
  }

  .stu-exam-card {
      padding: 0.75rem;
      margin-bottom: 0.75rem;
      width: 95%;
  }

  .status {
      padding: 0.1rem 0.4rem;
      height: 1.8vh;
      font-size: 0.8rem;
  }

  .stu-overview-cards {
      grid-template-columns: 1fr; /* Single column */
      gap: 0.5rem;
  }

  .stu-overview-card {
      padding: 5px 0;
      font-size: 0.85rem;
  }

  .stu-exam-history {
      margin-top: 10px;
  }

  .stu-exam-details {
      gap: 0.3rem;
  }

  .ttt {
      gap: 3px;
  }

  .exam-header {
      gap: 0.3rem;
  }
}