.error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}

.ge-divider {
    border: 1px solid #000;
    margin: 20px 0;
}

.ge-exam-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
}

.ge-row {
    display: flex;
    gap: 16px;
}

.ge-row-2 {
    display: flex;
    gap: 16px;
    width: 60%;
    align-items: center;
}

.ge-generate-button {
    margin-top: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    gap: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
}

.ge-generate-button:hover {
    transform: translateY(-5px);
}

.ge-form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ge-form-group label {
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
}

.ge-form-group input,
.ge-form-group select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
}

.ge-form-group input::placeholder {
    color: #999;
}

.ge-submit-questions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ge-submit-button {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.ge-submit-button:hover {
    background-color: #675e5e;
}

.create-question {
    margin-top: 20px;
    height: 135vh;
    scroll-behavior: smooth;
    width: 100%;
    box-sizing: border-box;
}

.question-block {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.question-block textarea {
    width: 96%;
    height: 150px;
    margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
    resize: none;
    outline: none;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 16px rgba(255, 255, 255, 0.6), -8px -8px 16px #fff;
}

.choices {
    display: flex;
    gap: 50px;
    margin-top: 10px;
    align-items: center;
}

.choice {
    width: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.choice label {
    font-weight: 500;
    font-size: 1.3em;
    width: 100%;
    text-align: center;
}

.choice input {
    padding: 5px;
    border-radius: 4px;
    outline: none;
    border: 2px solid #ccc;
    width: 95%;
    height: 4vh;
    box-shadow: 8px 8px 16px rgba(255, 255, 255, 0.6), -8px -8px 16px #fff;
}

.add-question-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 20px 0;
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
}

.add-question-btn:hover {
    background-color: #000;
    color: #fff;
}

.add-question-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .ge-exam-form {
        gap: 12px;
    }

    .ge-row,
    .ge-row-2 {
        gap: 12px;
    }

    .ge-row-2 {
        width: 70%;
    }

    .ge-form-group label {
        font-size: 13px;
        margin-bottom: 6px;
    }

    .ge-form-group input,
    .ge-form-group select {
        padding: 6px;
        font-size: 13px;
    }

    .ge-generate-button,
    .ge-submit-button {
        padding: 8px 15px;
        font-size: 14px;
    }

    .create-question {
        height: 120vh;
    }

    .question-block textarea {
        height: 120px;
        padding: 15px;
    }

    .choices {
        gap: 30px;
    }

    .choice {
        width: 45%;
    }

    .choice label {
        font-size: 1.2em;
    }

    .choice input {
        padding: 4px;
        height: 3.5vh;
    }

    .add-question-btn {
        padding: 8px 15px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .ge-exam-form {
        gap: 10px;
    }

    .ge-row {
        flex-direction: column; /* Stack form groups vertically */
        gap: 10px;
    }

    .ge-row-2 {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }

    .ge-form-group {
        width: 100%; /* Full width for form groups */
    }

    .ge-form-group label {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .ge-form-group input,
    .ge-form-group select {
        padding: 5px;
        font-size: 12px;
    }

    .ge-generate-button,
    .ge-submit-button {
        padding: 6px 12px;
        font-size: 13px;
        width: 100%;
        max-width: 200px;
    }

    .create-question {
        height: auto; /* Allow height to adjust to content */
        margin-top: 15px;
    }

    .question-block {
        padding: 8px;
        margin-bottom: 15px;
    }

    .question-block textarea {
        width: 100%;
        height: 100px;
        padding: 10px;
    }

    .choices {
        flex-direction: column; /* Stack choices vertically */
        gap: 20px;
    }

    .choice {
        width: 100%;
        padding: 5px;
    }

    .choice label {
        font-size: 1.1em;
    }

    .choice input {
        width: 100%;
        height: 3vh;
        padding: 4px;
    }

    .add-question-btn {
        padding: 6px 12px;
        width: 100%;
        max-width: 150px;
    }

    .ge-submit-questions {
        justify-content: center;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .error {
        font-size: 10px;
    }

    .ge-divider {
        margin: 15px 0;
    }

    .ge-exam-form {
        gap: 8px;
    }

    .ge-row,
    .ge-row-2 {
        gap: 8px;
    }

    .ge-form-group label {
        font-size: 11px;
        margin-bottom: 4px;
    }

    .ge-form-group input,
    .ge-form-group select {
        padding: 4px;
        font-size: 11px;
    }

    .ge-generate-button,
    .ge-submit-button {
        padding: 5px 10px;
        font-size: 12px;
        max-width: 120px;
        height: 4vh;
    }

    .create-question {
        margin-top: 10px;
        overflow-x: hidden;
    }
    .mcq{
        width: 88%;
    }
    .question-block {
        padding: 5px;
        margin-bottom: 10px;
    }

    .question-block textarea {
        height: 80px;
        padding: 8px;
    }

    .choices {
        gap: 15px;
    }

    .choice label {
        font-size: 1em;
    }

    .choice input {
        height: 2.5vh;
        padding: 3px;
    }

    .add-question-btn {
        padding: 5px 10px;
        max-width: 120px;
    }
}