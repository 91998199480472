/* ExamData.module.css */
:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --background-color: #f4f6f7;
    --text-color: #2c3e50;
    --border-radius: 12px;
}

.miniExamContainer {
    background-color: var(--background-color);
    min-height: 100vh;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.miniExamWrapper {
    max-width: 900px;
    margin: 100px auto 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.miniExamCard {
    background: white;
    border-radius: var(--border-radius);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.miniExamCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.miniCardHeader {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: var(--primary-color);
    color: white;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.miniCardBody {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.miniSubjectSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.miniSubjectIcon img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
}

.miniSubjectInfo {
    flex-grow: 1;
}

.miniBackButton {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.miniBackButton:hover {
    background-color: #27ae60;
}

.miniMarksSection {
    text-align: center;
    background-color: #f1f3f4;
    padding: 15px;
    border-radius: 10px;
}

.miniMarksValue {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
}

.miniExamQuestions {
    background: white;
    border-radius: var(--border-radius);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.miniQuestionBlock {
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.miniQuestionHeader {
    background-color: #f1f3f4;
    padding: 10px 15px;
    border-bottom: 1px solid #e0e0e0;
}

.miniQuestionNumber {
    font-weight: bold;
    color: var(--primary-color);
}

.miniQuestionContent {
    padding: 15px;
}

.miniAnswerSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.miniCorrectAnswer, .miniStudentAnswer {
    padding: 10px;
    border-radius: 8px;
}

.miniCorrectAnswer {
    background-color: rgba(46, 204, 113, 0.1);
    border: 1px solid #2ecc71;
}

.miniStudentAnswer {
    background-color: rgba(52, 152, 219, 0.1);
    border: 1px solid var(--primary-color);
}

@media (max-width: 768px) {
    .miniExamWrapper {
        margin: 80px 10px 20px 10px;
        padding: 0 10px;
    }

    .miniSubjectSection {
        flex-direction: column;
        text-align: center;
    }

    .miniBackButton {
        width: 100%;
        margin-top: 10px;
    }
}

/* Status Colors */
.passed {
    background-color: #2ecc71;
    color: white;
}

.failed {
    background-color: #e74c3c;
    color: white;
}

.pending {
    background-color: #f39c12;
    color: white;
}