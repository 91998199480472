.container {
    width: 450px;
    background: linear-gradient(
        0deg,
        rgb(255, 255, 255) 0%,
        rgb(244, 247, 251) 100%
    );
    border-radius: 40px;
    padding: 25px 35px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.heading {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: rgb(16, 137, 211);
}

.form {
    margin-top: 20px;
}

.form .input {
    width: 90%;
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: #cff0ff 0px 10px 10px -5px;
    border-inline: 2px solid transparent;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-container .input {
    flex: 1;
    padding-right: 40px;
}

.password-container .visibility-icon {
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
    color: #aaa;
    transition: color 0.2s;
}

.password-container .visibility-icon:hover {
    color: #12b1d1;
}

.form .input::-moz-placeholder {
    color: rgb(170, 170, 170);
}

.form .input::placeholder {
    color: rgb(170, 170, 170);
}

.form .input:focus {
    outline: none;
    border-inline: 2px solid #12b1d1;
}

.remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin-top: 20px;
}

.remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
}

.remember-forgot a {
    color: #0a0a0a;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

.form .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(
        45deg,
        rgb(16, 137, 211) 0%,
        rgb(18, 177, 209) 100%
    );
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
    transform: scale(1.03);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a {
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover {
    text-decoration: underline;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .container {
        width: 400px;
        padding: 20px 30px;
    }

    .heading {
        font-size: 26px;
    }

    .form .input {
        padding: 12px 18px;
        margin-top: 12px;
    }

    .password-container .visibility-icon {
        top: 22px;
        right: 8px;
    }

    .remember-forgot {
        font-size: 13.5px;
        margin-top: 15px;
    }

    .form .login-button {
        padding-block: 12px;
        margin: 15px auto;
    }

    .register-link {
        font-size: 13.5px;
        margin: 15px 0 10px;
    }

    .error-text {
        font-size: 11px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .container {
        width: 100%; /* Responsive width */
        max-width: 350px;
        padding: 15px 25px;
        border-radius: 30px;
    }

    .heading {
        font-size: 24px;
    }

    .form {
        margin-top: 15px;
    }

    .form .input {
        width: 90%; /* Full width */
        padding: 10px 15px;
        margin-top: 10px;
        border-radius: 15px;
    }

    .password-container .visibility-icon {
        top: 18px;
        right: 10px;
    }

    .remember-forgot {
        font-size: 13px;
        margin-top: 12px;
        flex-direction: column; /* Stack items vertically */
        gap: 8px;
        align-items: flex-start;
    }

    .form .login-button {
        padding-block: 10px;
        margin: 12px auto;
        border-radius: 15px;
    }

    .register-link {
        font-size: 13px;
        margin: 12px 0 8px;
    }

    .error-text {
        font-size: 10px;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .container {
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        border-width: 3px;
    }

    .heading {
        font-size: 20px;
    }

    .form {
        margin-top: 10px;
    }

    .form .input {
        padding: 8px 12px;
        margin-top: 8px;
        border-radius: 12px;
        width: 90%;
    }

    .password-container .visibility-icon {
        top: 13px;
        right: 8px;
        font-size: small;
    }

    .remember-forgot {
        font-size: 12px;
        margin-top: 10px;
        gap: 6px;
    }

    .form .login-button {
        padding-block: 8px;
        margin: 10px auto;
        border-radius: 12px;
        font-size: 14px;
    }

    .register-link {
        font-size: 12px;
        margin: 10px 0 6px;
    }

    .error-text {
        font-size: 9px;
        margin-top: 4px;
    }
}