.cls-teacher-home {
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
    box-sizing: border-box; /* Ensure padding doesn't overflow */
}

.cls-header {
    margin-bottom: 20px;
}

.cls-button {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 100px;
}

.cls-class-btn,
.cls-add-btn,
.cls-remove-btn {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 105px;
    font-size: 15px;
    cursor: pointer;
    margin-right: 10px;
    font-weight: 500;
    outline: none;
    transition: all 0.4s ease;
}

.cls-class-btn {
    background-color: #C2D9F6;
}

.cls-add-btn {
    background-color: #F2F8FF;
}

.cls-remove-btn {
    background-color: #FFDDDD;
}

.select-btn {
    padding: 8px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.cls-remove-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    opacity: 0.6;
}

.selected-row {
    background-color: #e0f7fa; /* Light teal for selected rows */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cls-stu-inner {
    width: 220px;
    position: absolute;
    top: 25%;
    left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 15vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-shadow: inset 4px 4px 8px rgba(200, 203, 208, 0.25), inset -4px -4px 8px rgba(255, 255, 255, 0.7);
}
.te-cls-stu-inner{
    width: 220px;
    position: absolute;
    top: 180px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 15vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-shadow: inset 4px 4px 8px rgba(200, 203, 208, 0.25), inset -4px -4px 8px rgba(255, 255, 255, 0.7);   
}
.cls-stu-inner h5 {
    width: 150px;
    height: 6vh;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
    box-shadow: 4px 4px 8px #C8CBD0, -4px -4px 8px #ffffff;
}
.te-cls-stu-inner h5 {
    width: 150px;
    height: 6vh;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
    box-shadow: 4px 4px 8px #C8CBD0, -4px -4px 8px #ffffff;
}
/* Checkbox styling */
.table td:first-child,
.table th:first-child {
    text-align: center;
}

.table td input[type="checkbox"],
.table th input[type="checkbox"] {
    cursor: pointer;
    transform: scale(1.2);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
}

.modal-content input,
.modal-content select {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.modal-content select {
    width: 95%;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-btn,
.cancel-btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.save-btn {
    background-color: #4CAF50;
    color: white;
}

.cancel-btn {
    background-color: #f44336;
    color: white;
}

.link-row {
    text-decoration: none;
    color: inherit; /* Preserve the current text color */
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
    .cls-teacher-home {
        padding: 15px;
    }

    .cls-button {
        margin-bottom: 80px;
        gap: 8px;
    }

    .cls-class-btn,
    .cls-add-btn,
    .cls-remove-btn {
        padding: 8px 15px;
        font-size: 14px;
        margin-right: 8px;
    }

    .select-btn {
        padding: 6px 10px;
        font-size: 13px;
    }

    .cls-stu-inner {
        width: 200px;
        height: 12vh;
    }

    .cls-stu-inner h5 {
        width: 130px;
        height: 5vh;
        font-size: 16px;
    }
    .te-cls-stu-inner {
        width: 200px;
        height: 12vh;
    }

    .te-cls-stu-inner h5 {
        width: 130px;
        height: 5vh;
        font-size: 16px;
    }
    .modal-content {
        width: 350px;
        padding: 15px;
    }

    .modal-content input,
    .modal-content select {
        padding: 8px;
    }

    .save-btn,
    .cancel-btn {
        padding: 8px 15px;
    }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
    .cls-teacher-home {
        padding: 10px;
    }

    .cls-header {
        margin-bottom: 15px;
    }

    .cls-button {
        flex-direction: column; /* Stack buttons vertically */
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 60px;
    }

    .cls-class-btn,
    .cls-add-btn,
    .cls-remove-btn {
        padding: 8px 12px;
        font-size: 13px;
        margin-right: 0;
        width: 100%; /* Full width for better touch targets */
        max-width: 200px; /* Limit width */
    }

    .select-btn {
        padding: 6px 8px;
        font-size: 12px;
    }

    .cls-stu-inner {
        width: 180px;
        height: 10vh;
        top: 250px;
        left: 20px;
    }

    .cls-stu-inner h5 {
        width: 120px;
        height: 4vh;
        font-size: 14px;
    }
    .te-cls-stu-inner {
        width: 180px;
        height: 10vh;
        top: 120px;
        left: 21px;
    }

    .te-cls-stu-inner h5 {
        width: 120px;
        height: 4vh;
        font-size: 14px;
    }
    .table td input[type="checkbox"],
    .table th input[type="checkbox"] {
        transform: scale(1);
    }

    .modal-content {
        width: 90%;
        max-width: 300px;
        padding: 10px;
    }

    .modal-content input,
    .modal-content select {
        padding: 6px;
        font-size: 14px;
    }

    .modal-actions {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px;
    }

    .save-btn,
    .cancel-btn {
        padding: 8px 10px;
        width: 100%;
    }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
    .cls-teacher-home {
        padding: 8px;
    }

    .cls-header {
        margin-bottom: 10px;
    }

    .cls-button {
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        
    }

    .cls-class-btn,
    .cls-add-btn,
    .cls-remove-btn {
        padding: 6px 10px;
        font-size: 12px;
        max-width: 150px;
    }

    .select-btn {
        padding: 5px 6px;
        font-size: 11px;
    }

    .cls-stu-inner {
        width: 150px;
        height: 8vh;
        top: 210px;
        left: 15px;
        display: none;
    }

    .cls-stu-inner h5 {
        width: 100px;
        height: 3.5vh;
        font-size: 12px;
    }
    .te-cls-stu-inner {
        width: 150px;
        height: 8vh;
        /* top: 35%;
        left: 15px; */
        display: none;
    }

    .te-cls-stu-inner h5 {
        width: 100px;
        height: 3.5vh;
        font-size: 12px;
    }

    .modal-content {
        width: 85%;
        max-width: 250px;
        padding: 8px;
    }

    .modal-content input,
    .modal-content select {
        padding: 5px;
        font-size: 12px;
    }

    .save-btn,
    .cancel-btn {
        padding: 6px 8px;
        font-size: 12px;
    }
}