/* Hero Section */
.hero {
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  background: white;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.backarrow {
  cursor: pointer;
}

.teacher-name-status h2 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  color: #1a237e;
}

.status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4caf50;
}

.status span {
  font-weight: 600;
  font-size: 0.9rem;
}

.vertical {
  height: 80px;
  width: 2px;
  background: #e0e0e0;
}

.empolyee {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.empolyee span {
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
}

.empolyee h2 {
  color: #1a237e;
  margin-top: 0.5rem;
  font-size: 1.8rem;
}

/* Details Block */
.details-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0;
}

.personal-details,
.address-details,
.contact-details,
.stu-exam-history,
.stu-academic-overview {
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.contact-details {
  height: 80%;
}

.stu-det {
  overflow-y: scroll;
  height: 20vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.student-info {
  border-radius: 10px;
  padding-left: 10px;
  background-color: #d4d4d4;
}

.personal-details .header,
.address-details .header,
.contact-details .header {
  margin-bottom: 10px;
}

.personal-details h2,
.address-details h2,
.contact-details h2 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #1976d2;
  font-size: 1.4rem;
  font-weight: 600;
}

.info-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.info span {
  color: #666;
  font-size: 0.9rem;
}

.info h5 {
  font-size: 1.1rem;
  margin-top: 0.25rem;
}

.address-details .det {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.det-in {
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
}

.det-in span {
  color: #666;
  font-size: 0.9rem;
}

.det-in h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 0.5rem;
}

.te-exams {
  padding: 1.5rem;
  border-radius: 12px;
  background: #f8f9fa;
  margin-bottom: 1rem;
  transition: transform 0.2s;
  cursor: pointer;
}

.te-exams h4 {
  color: #333;
  margin-bottom: 0.5rem;
}

.te-exams .det {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.te-exams .det span {
  color: #666;
}

.te-exams .horizon {
  height: 1px;
  background: #e0e0e0;
  margin: 1rem 0;
}

.det-in.completed {
  background: #e8f5e9;
  color: #2e7d32;
}

.det-in.pending {
  background: #ffebee;
  color: #c62828;
}

.address-details {
  background: #fff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-top: 2rem;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #1976d2;
}

.statistics-overview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.statistics-card {
  text-align: center;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
}

.statistics-card.completed {
  background: #e8f5e9;
  color: #2e7d32;
}

.statistics-card.pending {
  background: #ffebee;
  color: #c62828;
}

.main-exam-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.exam-card {
  padding: 1.5rem;
  border-radius: 12px;
  background: #f8f9fa;
  margin-bottom: 1rem;
  transition: transform 0.2s;
  cursor: pointer;
  width: 90%;
  height: fit-content;
  color: black;
}

.exam-card:hover {
  transform: scale(1.02);
}

.exam-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.exam-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.exam-questions {
  color: #666;
}

.status.completed {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}

.status.pending {
  background: #ffebee;
  color: #c62828;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}

/* Responsive Design */

/* Tablet (769px - 1024px) */
@media (max-width: 1024px) {
  .hero {
      padding: 1.5rem;
      gap: 1.5rem;
  }

  .teacher-name-status h2 {
      font-size: 1.8rem;
  }

  .status span {
      font-size: 0.85rem;
  }

  .vertical {
      height: 60px;
  }

  .empolyee h2 {
      font-size: 1.5rem;
  }

  .details-block {
      grid-template-columns: 1fr;
      gap: 1.5rem;
  }

  .personal-details,
  .address-details,
  .contact-details,
  .stu-exam-history,
  .stu-academic-overview {
      padding: 1.5rem;
  }

  .stu-det {
      grid-template-columns: 1fr;
      gap: 15px;
      height: 25vh;
  }

  .personal-details h2,
  .address-details h2,
  .contact-details h2 {
      font-size: 1.3rem;
  }

  .info-row {
      gap: 1rem;
  }

  .address-details .det {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
  }

  .statistics-overview {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
  }

  .main-exam-card {
      grid-template-columns: 1fr;
      gap: 15px;
  }

  .exam-card {
      width: 100%;
      padding: 1.2rem;
  }
}

/* Mobile (≤768px) */
@media (max-width: 768px) {
  .hero {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      gap: 1rem;
  }

  .teacher-name-status h2 {
      font-size: 1.6rem;
  }

  .status span {
      font-size: 0.8rem;
  }

  .vertical {
      display: none; /* Hide vertical line */
  }

  .empolyee {
      align-items: flex-start;
  }

  .empolyee h2 {
      font-size: 1.3rem;
  }

  .empolyee span {
      font-size: 0.8rem;
  }

  .details-block {
      gap: 1rem;
  }

  .personal-details,
  .address-details,
  .contact-details,
  .stu-exam-history,
  .stu-academic-overview {
      padding: 1rem;
  }

  .stu-det {
      height: 30vh;
      gap: 10px;
  }

  .personal-details h2,
  .address-details h2,
  .contact-details h2 {
      font-size: 1.2rem;
      gap: 0.5rem;
  }

  .info-row {
      grid-template-columns: 1fr;
      gap: 0.75rem;
  }

  .info span {
      font-size: 0.85rem;
  }

  .info h5 {
      font-size: 1rem;
  }

  .address-details .det {
      grid-template-columns: 1fr;
      gap: 0.75rem;
  }

  .det-in {
      padding: 0.75rem;
  }

  .det-in h3 {
      font-size: 1.3rem;
  }

  .te-exams {
      padding: 1rem;
  }

  .statistics-overview {
      grid-template-columns: 1fr;
      gap: 0.75rem;
  }

  .statistics-card {
      padding: 0.75rem;
  }

  .exam-card {
      padding: 1rem;
  }

  .exam-header,
  .exam-details {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
  }
}

/* Small Mobile (≤480px) */
@media (max-width: 480px) {
  .hero {
      padding: 0.75rem;
      margin-bottom: 1rem;
  }

  .teacher-name-status h2 {
      font-size: 1.4rem;
  }

  .status span {
      font-size: 0.75rem;
  }

  .empolyee h2 {
      font-size: 1.2rem;
  }

  .empolyee span {
      font-size: 0.75rem;
  }

  .details-block {
      gap: 0.75rem;
      margin: 1rem 0;
  }

  .personal-details,
  .address-details,
  .contact-details,
  .stu-exam-history,
  .stu-academic-overview {
      padding: 0.75rem;
  }

  .stu-det {
      height: 35vh;
      gap: 8px;
  }

  .personal-details h2,
  .address-details h2,
  .contact-details h2 {
      font-size: 1.1rem;
      gap: 0.4rem;
  }

  .info span {
      font-size: 0.8rem;
  }

  .info h5 {
      font-size: 0.9rem;
  }

  .det-in span {
      font-size: 0.8rem;
  }

  .det-in h3 {
      font-size: 1.2rem;
  }

  .te-exams h4 {
      font-size: 1rem;
  }

  .te-exams .det span {
      font-size: 0.85rem;
  }

  .statistics-card {
      padding: 0.5rem;
  }

  .exam-card {
      padding: 0.75rem;
  }
}