@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

/* Base styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 12vh;
    width: 90%;
    position: fixed;
    top: 2%;
    left: 5%;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.navigation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.password-container-1{
    border: 1px solid rgba(78, 138, 149, 0.2);
    margin-bottom: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
}
.password-container-1 .input-1{
    border: none;
}

.app-title {
    font-family: 'Caveat', cursive;
    font-weight: normal;
    position: relative;
    left: 30px;
}

.app-title h1 {
    text-decoration: none;
}

ol.menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    /* gap: 40px; */
    margin: 0;
    padding: 0;
}

.menu-items {
    width: fit-content;
    padding: 0 30px;
    text-align: center;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    font-size: 21.7px;
    font-weight: 500;
}

.menu-items:hover {
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(78, 138, 149, 0.2), -5px -5px 5px #fff;
}

.menu-items a {
    text-decoration: none;
    color: black;
}

.logout-button {
    background-color: rgba(51, 114, 237, 0.9);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    height: 40px;
    font-size: 15px;
}

.menu-items.active {
    border-radius: 10px;
    box-shadow: inset 4px 4px 8px rgba(200, 203, 208, 1), inset -4px -4px 4px #fff;
}

/* Hamburger button */
.hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: relative;
    right: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .navbar {
        height: 10vh;
        padding: 0 5px;
        width: 94%;
        left: 3%;
    }

    .navigation {
        justify-content: space-between;
    }

    .app-title {
        left: 10px;
    }

    .hamburger {
        display: block; /* Show hamburger on smaller screens */
    }

    ol.menu {
        display: none; /* Hide menu by default on small screens */
        flex-direction: column;
        position: absolute;
        top: 10vh;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        z-index: 999;
    }

    ol.menu.open {
        display: flex; /* Show menu when open */
    }

    .menu-items {
        width: 80%;
        font-size: 18px;
        margin-left: 5%;
        justify-content: center;
    }

    .logout-item {
        padding: 15px 0;
        width: 90%;
    }

    .logout-button {
        font-size: 18px;
        height: auto;
        padding: 10px 20px;
        background: none;
        color: black;
        font-weight: bold;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .navbar {
        height: 8vh;
    }

    .app-title span:first-child {
        font-size: 40px;
    }

    .app-title span:last-child {
        font-size: 30px;
    }

    .hamburger {
        font-size: 25px;
        right: 10px;
    }

    .menu-items {
        font-size: 16px;
    }

    .logout-button {
        font-size: 16px;
        padding: 8px 15px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .navbar {
        width: 92%;
        left: 4%;
    }

    .navigation {
        gap: 40px;
    }

    .menu-items {
        padding: 0 20px;
        font-size: 20px;
    }
}