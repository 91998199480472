
    .interview-main {
        height: 99vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .inv-container {
        height: 94%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .questions {
        width: 65%;
        height: 100%;
        overflow-y: auto;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .question-block {
        margin: 20px 20px 0 5px;
        padding: 7px 20px;
        width: 90%;
        word-wrap: break-word;
        border-radius: 20px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px #fff;
        background-color: #f9f9f9;
        transition: background-color 0.3s ease;
    }
    /* General Styling */
    .question-number {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease;
        background-color: #f9f9f9;
    }

    /* States */
    .question-number.visited {
        background-color: #e7f3ff;
    }

    .question-number.answered {
        border-color: #28a745;
        background-color: #d4edda;
        color: #155724;
    }

    .question-number.not-visited {
        border-color: #6c757d;
        background-color: #f9f9f9;
        color: #6c757d;
    }

    /* Hover Effects */
    .question-number:hover {
        transform: scale(1.1);
        border-color: #343a40;
    }

    /* Descriptive Answer Styles */
    .descriptive-answer {
        width: 50%;
        margin: 10px 0;
        padding: 5px;
        /* border: 1px solid #ccc; */
        border-radius: 5px;
        font-size: 14px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        resize: none;
        border:  solid;
    }

    .descriptive-answer:focus {
        outline: none;
        box-shadow: 0px 0px 10px rgba(0, 111, 255, 0.2);
    }


    .question-block:hover {
        background-color: #e9e9e9;
    }

    .question-block h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .options label {
        display: block;
        margin: 5px 0;
        cursor: pointer;
    }

    .time-numbers {
        width: 35%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .timer {
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inv-number {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        padding: 20px;
        overflow-y: auto;
        height: 40%;
        width: 100%;
    }

    .question-number {
        width: 60px;
        height: 60px;
        margin: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f9f9f9;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
    }

    .question-number:hover {
        transform: scale(1.05);
    }

    .question-number.visited {
        background-color: orange;
    }


    .inv-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;
    }

    .inv-submit-btn {
        width: 200px;
        height: 45px;
        background-color: #000000;
        color: white;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }

    .inv-submit-btn:hover {
        background-color: #413f3f;
    }

    .inv-submit-btn:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

    /* Responsive Styles */

    /* Tablets (Width between 768px and 1024px) */
    @media (max-width: 1024px) and (min-width: 768px) {
        .inv-container {
            flex-direction: row;
        }

        .questions {
            width: 60%;
        }

        .time-numbers {
            width: 35%;
        }

        .number{
            grid-template-columns: repeat(4, 1fr);
        }

        .question-number {
            width: 50px;
            height: 50px;
            font-size: 14px;
        }

        .question-block {
            width: 92%;
        }
    }

    /* Large Mobile Devices (Width between 480px and 767px) */
    @media (max-width: 767px) and (min-width: 480px) {
        .inv-container {
            flex-direction: column;
        }

        .questions {
            width: 100%;
            height: 60%;
        }

        .time-numbers {
            width: 100%;
            height: 40%;
            flex-direction: row;
            justify-content: center;
        }

        .timer {
            height: 30%;
        }

        .number {
            grid-template-columns: repeat(4, 1fr);
            height: 70%;
        }

        .question-number {
            width: 45px;
            height: 45px;
            font-size: 14px;
        }

        .question-block {
            width: 95%;
        }
        .descriptive-answer{
            width: 90%;
            border: solid;
        }
    }

    /* Small Mobile Devices (Width below 480px) */
    @media (max-width: 479px) {
        .inv-head {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            padding: 10px;
        }

        .inv-btn {
            margin-top: 10px;
            height: 35px;
            padding: 1px 8px;
            font-size: 14px;
        }

        .inv-container {
            flex-direction: column;
            height: calc(100vh - 16vh); /* Adjusted for header and footer */
        }

        .questions {
            width: 100%;
            height: 60%;
            padding: 10px 0;
        }

        .time-numbers {
            width: 100%;
            height: 40%;
            flex-direction: row;
            justify-content: space-around;
            padding: 10px 0;
        }

        .timer {
            height: 40%;
        }

        .number {
            grid-template-columns: repeat(3, 1fr);
            height: 60%;
            padding: 10px;
        }

        .question-number {
            width: 40px;
            height: 40px;
            font-size: 12px;
        }

        .question-block {
            width: 100%;
            margin: 15px 0;
        }

        .question-block h3 {
            font-size: 14px;
        }

        .options label {
            font-size: 14px;
        }

        .inv-sub {
            height: 8vh;
        }

        .inv-submit-btn {
            width: 150px;
            height: 40px;
            font-size: 14px;
        }
    }
    /* Timer.css */

    /* Base Styles (Existing) */

    .timer-container {
        display: flex;
        flex-direction: column;
        margin: 2% 0;
        align-items: center;
    }
    
    .timer-label {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 5px solid; 
        display: flex;
        justify-content: center;
        align-items: center;
        transition: border-color 0.3s ease;
        margin-bottom: 2%;
    }
    
    .time {
        font-size: 24px;
        font-weight: bold;
    }
    
    /* Blinking animation for the time */
    .blinking .time {
        animation: blink-animation 1s steps(5, start) infinite;
        color: red;
    }
    
    @keyframes blink-animation {
        to {
        visibility: hidden;
        }
    }
    
    /* Style for warning message */
    .warning-message {
        margin-top: 20px;
        font-size: 18px;
        color: red; 
        position: absolute;
        bottom: 20px; 
        left: 75%; 
        transform: translateX(-50%);
    }
    
    /* Responsive Styles */
    
    /* Tablets (Width between 768px and 1024px) */
    @media (max-width: 1024px) and (min-width: 768px) {
        .timer-container {
        margin: 1.5% 0;
        }
    
        .timer-label {
        font-size: 16px;
        margin-bottom: 8px;
        }
    
        .circle {
        width: 130px;
        height: 130px;
        border-width: 4px;
        }
    
        .time {
        font-size: 20px;
        }
    
        .warning-message {
        left: 80%;
        font-size: 16px;
        }
    }
    
    /* Large Mobile Devices (Width between 480px and 767px) */
    @media (max-width: 767px) and (min-width: 480px) {
        .timer-container {
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
        margin: 1% 0;
        }
    
        .timer-label {
        font-size: 16px;
        margin-bottom: 0;
        margin-right: 10px;
        }
    
        .circle {
        width: 120px;
        height: 120px;
        border-width: 4px;
        }
    
        .time {
        font-size: 20px;
        }
    
        .warning-message {
        left: 80%;
        font-size: 16px;
        bottom: 15px;
        }
    }
    
    /* Small Mobile Devices (Width below 480px) */
    @media (max-width: 479px) {
        .timer-container {
        flex-direction: column;
        margin: 1% 0;
        }
    
        .timer-label {
        font-size: 16px;
        margin-bottom: 8px;
        }
    
        .circle {
        width: 100px;
        height: 100px;
        border-width: 3px;
        }
    
        .time {
        font-size: 18px;
        }
    
        .warning-message {
        left: 83%;
        font-size: 10px;
        bottom: 10px;
        transform: translateX(-50%);
        }
    }





